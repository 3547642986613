import { signOut, useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import IconButton from 'client/elements/IconButton';
import { AccountCircleIcon } from 'client/elements/Icons';
import Link from 'client/elements/Link';
import Menu from 'client/elements/Menu';
import MenuItem from 'client/elements/MenuItem';
import Typography from 'client/elements/Typography';
import useCart from 'client/hooks/useCart';
import useSignIn from 'client/hooks/useSignIn';
import useUserGroup from 'client/hooks/useUserGroup';
import { useTranslation } from 'client/utils/i18n';
import styles from './UserMenu.module.css';
const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
} as const;
const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
} as const;
export interface UserMenuProps {
  toggleSaveCart: () => void;
}
export const UserMenu = ({
  toggleSaveCart
}: UserMenuProps) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const path = usePathname();
  const {
    openSignInDialog
  } = useSignIn();
  const {
    onUserChange
  } = useCart();
  const session = useSession();
  const group = useUserGroup();
  const [accountMenu, setAccountMenu] = useState<HTMLElement | null>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => setAccountMenu(event.currentTarget), []);
  const handleClose = useCallback(() => setAccountMenu(null), []);
  return <>
      <div className={styles.wrapper}>
        <IconButton aria-owns={accountMenu ? 'menu-appbar' : undefined} aria-haspopup="true" aria-label="menu" onClick={handleOpen} color="inherit" size="large" data-sentry-element="IconButton" data-sentry-source-file="UserMenu.tsx">
          <AccountCircleIcon data-sentry-element="AccountCircleIcon" data-sentry-source-file="UserMenu.tsx" />
        </IconButton>
        {session.data?.user && <Typography color="inherit" className={styles.userName}>
            {session.data.user.lastname}
          </Typography>}
      </div>
      <Menu id="menu-appbar" onClose={handleClose} anchorEl={accountMenu} open={!!accountMenu} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} data-sentry-element="Menu" data-sentry-source-file="UserMenu.tsx">
        {session.data?.user && <div>
            {group.id > 1 && group.id !== 40 && <>
                <MenuItem onClick={() => {
            toggleSaveCart();
            handleClose();
          }}>
                  {t('main:save_cart')}
                </MenuItem>
                <Link href="/carts" color="inherit" underline="none">
                  <MenuItem onClick={handleClose}>{t('main:manage_carts')}</MenuItem>
                </Link>
              </>}
            {/* <Link href="/account/orders" color="inherit" underline="none">
              <MenuItem onClick={handleClose}>{t('main:my_orders')}</MenuItem>
             </Link> */}
            <Link href="/account" color="inherit" underline="none">
              <MenuItem onClick={handleClose}>{t('main:account_page_title')}</MenuItem>
            </Link>
            {(session.data.user.role === 'salesperson' || session.data.user.role === 'administrator' || session.data.user.role === 'developer') && <Link href="/admin" color="inherit" underline="none">
                <MenuItem onClick={handleClose}>{t('main:admin')}</MenuItem>
              </Link>}
            <MenuItem onClick={async () => {
          handleClose();
          await signOut({
            redirect: false
          });
          onUserChange({
            action: 'sign-out'
          });
          router.refresh();
        }}>
              {t('main:sign_out')}
            </MenuItem>
          </div>}
        {!session.data?.user && <div>
            <Link href={`/create-account?returnTo=${path || '/'}`} color="inherit" underline="none">
              <MenuItem onClick={handleClose}>{t('main:sign_up')}</MenuItem>
            </Link>
            <MenuItem onClick={() => {
          openSignInDialog();
          setAccountMenu(null);
        }}>
              {t('main:sign_in')}
            </MenuItem>
          </div>}
      </Menu>
    </>;
};