'use client';

import { createTheme } from '@mui/material/styles';
import { Mulish } from 'next/font/google';

const font = Mulish({
  subsets: ['latin'],
  display: 'swap',
});

export interface UserTheme {
  primaryColor?: string;
  secondaryColor?: string;
}

export const getTheme = (userTheme?: UserTheme) => {
  return createTheme({
    cssVariables: true,
    palette: {
      primary: {
        main: userTheme?.primaryColor || '#588a7f',
      },
      secondary: {
        main: userTheme?.secondaryColor || '#deb772',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.75)',
      },
      warning: {
        main: '#ffa000',
      },
      success: {
        main: '#43a047',
      },
      info: {
        main: '#1976d2',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      h1: {
        fontWeight: 'bold',
        fontSize: '2rem',
      },
      h2: {
        fontWeight: 'bold',
        fontSize: '1.7rem',
      },
      h3: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
      },
      h4: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
      },
      h5: {
        fontWeight: 'bold',
        fontSize: '1.3rem',
      },
      h6: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
      },
      subtitle1: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
      },
      subtitle2: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
      },
      fontFamily: font.style.fontFamily,
      fontSize: 16,
    },

    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: font.style.fontFamily,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          entered: {
            height: 'auto',
            overflow: 'visible',
          },
        },
      },
    },
  });
};

export const defaultTheme = getTheme();
