'use client';

import { useEffect } from 'react';
const ANALYTICS_ID = 'UA-3864449-1';
const TrackPageView = () => {
  useEffect(() => {
    window.gtag('config', ANALYTICS_ID);
  }, []);
  return null;
};
export default TrackPageView;