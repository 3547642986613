'use client';

import axios, { AxiosResponse } from 'axios';
import { IncomingHttpHeaders } from 'http';
import * as t from 'io-ts';
import { SessionContextValue, useSession } from 'next-auth/react';
import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider, QueryKey, UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient as useReactQueryClient } from 'react-query';
import { HttpErrorData } from 'types/http';
import applicationInsights, { SeverityLevel } from 'utils/application-insights';
import { getErrorMessages, validate } from 'utils/type';
type ErrorCode = 'request_type_check_response_failed' | HttpErrorData['code'] | 'request_400' | 'request_401' | 'request_402' | 'request_403' | 'request_404' | 'request_405' | 'request_407' | 'request_408' | 'request_409' | 'request_500' | 'request_502' | 'request_503' | 'request_504' | 'request_failed';
export class HttpError extends Error {
  public readonly errorCode: ErrorCode;
  public readonly statusCode?: number;
  constructor(message: string, errorCode: ErrorCode, statusCode?: number) {
    super(message);
    this.errorCode = errorCode;
    this.statusCode = statusCode;
  }
}
export const APIProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const queryClient = React.useMemo(() => new QueryClient(), []);
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="APIProvider" data-sentry-source-file="useAPI.tsx">{children}</QueryClientProvider>;
};
interface CreateMakeRequestParams<RETURN_TYPE> {
  url: string;
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  type: t.Type<RETURN_TYPE, any, t.mixed>;
  session: SessionContextValue;
  headers?: IncomingHttpHeaders;
}
function createMakeRequest<DATA, RETURN_TYPE>({
  url,
  method,
  type,
  session,
  headers
}: CreateMakeRequestParams<RETURN_TYPE>) {
  return async function makeRequest(data: DATA): Promise<RETURN_TYPE> {
    const date = Date.now();
    let response: AxiosResponse<unknown>;
    try {
      if (method === 'post' || method === 'put' || method === 'patch') {
        response = await axios[method]<unknown>(url, data, {
          headers
        });
      } else {
        response = await axios[method]<unknown>(url, {
          headers
        });
      }
    } catch (error) {
      const statusCode = axios.isAxiosError(error) ? error.response?.status : undefined;
      const bodyResult = axios.isAxiosError(error) ? validate(HttpErrorData, error.response?.data) : undefined;
      const errorCode = bodyResult?.isValid ? bodyResult.data.code : statusCode ? `request_${statusCode}` as ErrorCode : 'request_failed';
      console.error(`Request failed ${url}: ${axios.isAxiosError(error) ? JSON.stringify(error.toJSON()) : `${error}`}`);
      const errorCodesToIgnore = ['ECONNABORTED', 'ERR_NETWORK'];
      const shouldIgnore = axios.isCancel(error) || axios.isAxiosError(error) && error.code && errorCodesToIgnore.includes(error.code);
      if (!shouldIgnore) {
        applicationInsights.trackTrace(`Failed ${method.toUpperCase()} ${url}`, {
          url,
          method,
          statusCode: statusCode?.toString(),
          duration: (Date.now() - date).toString(),
          isAxiosError: axios.isAxiosError(error).toString(),
          error: axios.isAxiosError(error) ? JSON.stringify(error.toJSON()) : `${error}`,
          response: axios.isAxiosError(error) ? JSON.stringify(error.response) : undefined,
          user: JSON.stringify(session.data?.user)
        }, SeverityLevel.Error);
      }
      throw new HttpError(bodyResult?.isValid && bodyResult.data.message ? bodyResult.data.message : axios.isAxiosError(error) ? error.message : `${error}`, errorCode, statusCode);
    }
    if (type.name === 'void') {
      return undefined as unknown as RETURN_TYPE;
    }
    const validated = validate(type, response.data);
    if (!validated.isValid) {
      const errorMessages = getErrorMessages(validated.errors);
      applicationInsights.trackTrace('useGetAPI: response type checking failed', {
        url,
        user: JSON.stringify(session.data?.user),
        data: JSON.stringify(response.data),
        error: JSON.stringify(errorMessages)
      }, SeverityLevel.Critical);
      console.error(`Invalid data returned from ${url}: ${errorMessages.join('\n')}`);
      throw new HttpError(`Invalid data: ${errorMessages.join('\n')}`, 'request_type_check_response_failed', response.status);
    }
    return validated.data;
  };
}
export interface UseGetAPIParams<RETURN_TYPE> extends UseQueryOptions<RETURN_TYPE, HttpError> {
  headers?: IncomingHttpHeaders;
  type: t.Type<RETURN_TYPE, any, t.mixed>;
  queryKey: QueryKey;
  shouldFetch?: boolean;
}
export const useGetAPI = <RETURN_TYPE,>(url: string, {
  headers,
  type,
  queryKey,
  shouldFetch = true,
  ...options
}: UseGetAPIParams<RETURN_TYPE>) => {
  const session = useSession();
  const makeRequest = React.useMemo(() => createMakeRequest<unknown, RETURN_TYPE>({
    url,
    method: 'get',
    type,
    session,
    headers
  }), [url, headers, type, session]);
  return useQuery<RETURN_TYPE, HttpError>(queryKey, makeRequest, {
    ...options,
    enabled: shouldFetch
  });
};
interface UseMutateAPIParams<PAYLOAD = void, RETURN_TYPE = unknown> extends UseMutationOptions<RETURN_TYPE, HttpError, PAYLOAD> {
  headers?: IncomingHttpHeaders;
  type: t.Type<RETURN_TYPE, any, t.mixed>;
}
const useMutateAPI = <PAYLOAD = void, RETURN_TYPE = unknown>(url: string, method: 'post' | 'put' | 'patch' | 'delete', {
  headers,
  type,
  ...options
}: UseMutateAPIParams<PAYLOAD, RETURN_TYPE>) => {
  const session = useSession();
  const makeRequest = React.useMemo(() => createMakeRequest<PAYLOAD, RETURN_TYPE>({
    url,
    method,
    headers,
    type,
    session
  }), [url, method, headers, type, session]);
  return useMutation(makeRequest, options);
};
export type UsePostAPIParams<PAYLOAD = void, RETURN_TYPE = unknown> = UseMutateAPIParams<PAYLOAD, RETURN_TYPE>;
export const usePostAPI = <PAYLOAD = void, RETURN_TYPE = unknown>(url: string, params: UsePostAPIParams<PAYLOAD, RETURN_TYPE>) => useMutateAPI(url, 'post', params);
export type UsePutAPIParams<PAYLOAD = void, RETURN_TYPE = unknown> = UseMutateAPIParams<PAYLOAD, RETURN_TYPE>;
export const usePutAPI = <PAYLOAD = void, RETURN_TYPE = unknown>(url: string, params: UsePutAPIParams<PAYLOAD, RETURN_TYPE>) => useMutateAPI(url, 'put', params);
export type UsePatchAPIParams<PAYLOAD = void, RETURN_TYPE = unknown> = UseMutateAPIParams<PAYLOAD, RETURN_TYPE>;
export const usePatchAPI = <PAYLOAD = void, RETURN_TYPE = unknown>(url: string, params: UsePatchAPIParams<PAYLOAD, RETURN_TYPE>) => useMutateAPI(url, 'patch', params);
export type UseDeleteAPIParams<PAYLOAD = void, RETURN_TYPE = unknown> = UseMutateAPIParams<PAYLOAD, RETURN_TYPE>;
export const useDeleteAPI = <PAYLOAD = void, RETURN_TYPE = unknown>(url: string, params: UseDeleteAPIParams<PAYLOAD, RETURN_TYPE>) => useMutateAPI(url, 'delete', params);
export const useQueryClient = useReactQueryClient;