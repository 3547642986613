import { Session } from 'next-auth';
import { SessionProvider as NextAuthSessionProvider, SessionProviderProps as NextAuthSessionProviderProps, useSession as useNextAuthSession } from 'next-auth/react';
import { ReactNode } from 'react';
export const useSession = useNextAuthSession;
export type Role = Session['user']['role'];
type ChildrenFunc = (session?: ReturnType<typeof useSession>['data']) => ReactNode;
const InnerSession = ({
  children
}: {
  children: ChildrenFunc;
}) => {
  return children(useSession().data);
};
export const SessionProvider = ({
  children,
  ...restProps
}: {
  children: ChildrenFunc | ReactNode;
} & Omit<NextAuthSessionProviderProps, 'children'>) => {
  return <NextAuthSessionProvider {...restProps} data-sentry-element="NextAuthSessionProvider" data-sentry-component="SessionProvider" data-sentry-source-file="useSession.tsx">
      {typeof children === 'function' ? <InnerSession>{children}</InnerSession> : children}
    </NextAuthSessionProvider>;
};