'use client';

import RouterLink from 'next/link';
import React, { ReactNode } from 'react';
export interface LinkBehaviorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children: ReactNode;
  href: string;
  replace?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  useRouter?: boolean;
}
export const LinkBehavior = React.forwardRef<any, LinkBehaviorProps>(function LinkBehavior({
  children,
  href,
  replace,
  target = '_self',
  useRouter = true,
  ...other
}, ref) {
  const isSameOrigin = React.useMemo(() => {
    if (typeof window === 'undefined') return false;
    const a = window.document.createElement('a');
    a.href = href;
    const url = new URL(a.href);
    return url.origin === window.location.origin;
  }, [href]);
  if (useRouter && target === '_self' && isSameOrigin) {
    return <RouterLink href={href.replace(window.location.origin, '')} ref={ref} replace={replace} target={target} {...other}>
        {children}
      </RouterLink>;
  }
  return <a {...other} href={href} ref={ref} target={target}>
      {children}
    </a>;
});