'use client';

import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { UserTheme, getTheme } from './theme';
type MuiProviderProps = {
  children: ReactNode;
  userTheme: UserTheme | undefined;
};
export const ThemeProvider = ({
  children,
  userTheme
}: MuiProviderProps) => {
  const theme = React.useMemo(() => getTheme(userTheme), [userTheme]);
  return <MUIThemeProvider theme={theme} data-sentry-element="MUIThemeProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">{children}</MUIThemeProvider>;
};