import 'client/utils/polyfills';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { Session } from 'next-auth';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import BrowserChecker from 'client/components/BrowserChecker';
import GTM from 'client/components/GTM';
import { MUIProvider } from 'client/components/MUI';
import { APIProvider } from 'client/hooks/useAPI';
import { CalculatedCartItemsDetailsProvider } from 'client/hooks/useCalculatedCartItemsDetails';
import { CartProvider } from 'client/hooks/useCart';
import { NotificationsProvider } from 'client/hooks/useNotifications';
import { SessionProvider } from 'client/hooks/useSession';
import { SignInProvider } from 'client/hooks/useSignIn';
import { getGroup } from 'client/hooks/useUserGroup';
import { I18nProvider } from 'client/utils/i18n';
import { OptimizelyProvider } from 'client/utils/optimizely';
interface MyAppProps extends AppProps<{
  session?: Session;
  messages?: Record<string, string>;
}> {
  emotionCache?: React.ComponentPropsWithoutRef<typeof AppCacheProvider>['emotionCache'];
}
const MyApp = ({
  Component,
  pageProps: {
    session,
    messages,
    ...pageProps
  },
  emotionCache
}: MyAppProps) => {
  const router = useRouter();
  return <I18nProvider locale={router.locale} messages={messages} data-sentry-element="I18nProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <APIProvider data-sentry-element="APIProvider" data-sentry-source-file="_app.tsx">
        <AppCacheProvider emotionCache={emotionCache} data-sentry-element="AppCacheProvider" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <meta charSet="utf-8" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            <meta name="viewport" content="user-scalable=0, initial-scale=1, minimum-scale=1, width=device-width, height=device-height" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />

            <title>Crédence-inox.com</title>
            <link rel="icon" href="/favicon.ico" />

            <style>{'body { margin: 0; padding: 0; }'}</style>
          </Head>
          <GTM data-sentry-element="GTM" data-sentry-source-file="_app.tsx" />
          <BrowserChecker data-sentry-element="BrowserChecker" data-sentry-source-file="_app.tsx">
            <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-source-file="_app.tsx">
              {session => <OptimizelyProvider>
                  <MUIProvider userTheme={getGroup(session?.user.groupId)?.theme}>
                    <NotificationsProvider>
                      <CartProvider>
                        <SignInProvider>
                          <CalculatedCartItemsDetailsProvider>
                            <Component {...pageProps} />
                          </CalculatedCartItemsDetailsProvider>
                        </SignInProvider>
                      </CartProvider>
                    </NotificationsProvider>
                  </MUIProvider>
                </OptimizelyProvider>}
            </SessionProvider>
          </BrowserChecker>
        </AppCacheProvider>
      </APIProvider>
    </I18nProvider>;
};
export default MyApp;