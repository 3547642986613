'use client';

import { useSession } from 'next-auth/react';
import React, { ReactNode, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { useInvalidateCart, useCart as useServerCart } from 'client/services/cart-items';
import * as cartItems from 'client/services/cart-items';
import { Cart as CartType } from 'types/cart';
import { CartItem } from 'types/carts';
import { CartItemId } from 'types/ids';
import { OptionsValues, ProductId } from 'types/products';
import { migratePropertiesValues } from 'utils/product';
export { CartItem };
export interface Cart {
  items: Omit<CartType['items'][number], 'createdAt' | 'updatedAt'>[];
  updatedAt: string;
}
interface CartContextValue {
  loading: boolean;
  cartItems: CartItem[];
  addCartItem: (productId: ProductId, optionsValues: OptionsValues, quantity: number) => Promise<void>;
  updateCartItem: (cartItem: CartItem) => Promise<void>;
  removeCartItem: (cartItemId: CartItemId) => Promise<void>;
  clearCart: () => Promise<void>;
  onUserChange: (params: {
    action: 'sign-out';
  } | {
    action: 'sign-in';
    newUserId: number;
    previousUserId?: number;
  }) => Promise<void>;
}
const CartContext = React.createContext<CartContextValue | undefined>(undefined);
const STORAGE_KEY_V2 = 'cart-v2';
const defaultValue: Cart = {
  items: [],
  updatedAt: new Date(2000, 1, 1).toISOString()
};

// Migration from V1 to V2 for users with no account without loosing data
if (typeof window !== 'undefined') {
  const STORAGE_KEY_V1 = 'cart';
  if (localStorage[STORAGE_KEY_V1]) {
    const cartV1 = JSON.parse(localStorage[STORAGE_KEY_V1]);
    if (cartV1 && cartV1.length > 1) {
      const cartV2: Cart = {
        items: cartV1,
        updatedAt: defaultValue.updatedAt
      };
      localStorage[STORAGE_KEY_V2] = JSON.stringify(cartV2);
    }
    localStorage.removeItem(STORAGE_KEY_V1);
  }
}
export const CartProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const session = useSession();
  const invalidateCart = useInvalidateCart();
  const storageKey = session.data ? `${STORAGE_KEY_V2}-${session.data.user.idAsNumber}` : STORAGE_KEY_V2;
  const [preProcessedLocalStorageCart, setLocalStorageCart, {
    removeItem: deleteCart
  }] = useLocalStorageState<Cart>(storageKey, {
    defaultValue
  });
  const localStorageCart = useMemo(() => {
    return {
      ...preProcessedLocalStorageCart,
      items: preProcessedLocalStorageCart.items.map(cartItem => {
        const {
          optionsValues
        } = migratePropertiesValues(cartItem.productId, cartItem.propertiesValues, cartItem.optionsValues);
        if (cartItem.optionsValues === optionsValues) return cartItem;
        return {
          ...cartItem,
          optionsValues
        };
      })
    };
  }, [preProcessedLocalStorageCart]);
  const {
    data: preProcessedServerCart
  } = useServerCart(session.data?.user.idAsNumber);
  const serverCart = useMemo(() => {
    if (!preProcessedServerCart) return preProcessedServerCart;
    return {
      ...preProcessedServerCart,
      items: preProcessedServerCart.items.map(cartItem => {
        const {
          optionsValues
        } = migratePropertiesValues(cartItem.productId, cartItem.propertiesValues, cartItem.optionsValues);
        if (cartItem.optionsValues === optionsValues) return cartItem;
        return {
          ...cartItem,
          optionsValues
        };
      })
    };
  }, [preProcessedServerCart]);

  // Updating the localStorage data if the data from the server is more recent
  React.useEffect(() => {
    if (serverCart && new Date(serverCart.updatedAt) > new Date(localStorageCart.updatedAt)) setLocalStorageCart({
      ...serverCart,
      updatedAt: serverCart.updatedAt.toISOString()
    });
  }, [serverCart, localStorageCart, setLocalStorageCart]);
  const removeCartItem = React.useCallback(async (cartItemId: number) => {
    const result = session.data?.user.idAsNumber ? await cartItems.removeCartItem(session.data.user.idAsNumber, cartItemId) : undefined;
    setLocalStorageCart(previousData => {
      if (result) return {
        ...result,
        updatedAt: result.updatedAt.toISOString()
      };
      return {
        items: previousData.items.filter(({
          id
        }) => id !== cartItemId),
        updatedAt: new Date().toISOString()
      };
    });
  }, [session.data?.user.idAsNumber, setLocalStorageCart]);
  const updateCartItem = React.useCallback(async (cartItem: CartItem) => {
    if (cartItem.quantity === 0) return await removeCartItem(cartItem.id);
    const result = session.data?.user.idAsNumber ? await cartItems.updateCartItem(session.data.user.idAsNumber, cartItem.id, cartItem) : undefined;
    setLocalStorageCart(previousData => {
      if (result) return {
        ...result,
        updatedAt: result.updatedAt.toISOString()
      };
      return {
        items: previousData.items.map(item => item.id === cartItem.id ? cartItem : item),
        updatedAt: new Date().toISOString()
      };
    });
  }, [removeCartItem, session.data?.user.idAsNumber, setLocalStorageCart]);
  const addCartItem = React.useCallback(async (productId: ProductId, optionsValues: OptionsValues, quantity: number) => {
    // If the product already is in the basket, only increase its quantity
    const existingCartItem = localStorageCart.items.find(item => item.productId === productId && JSON.stringify(optionsValues) === JSON.stringify(item.optionsValues));
    if (existingCartItem) return updateCartItem({
      ...existingCartItem,
      quantity: existingCartItem.quantity + quantity
    });
    const result = session.data?.user.idAsNumber ? await cartItems.addCartItem(session.data.user.idAsNumber, {
      productId,
      optionsValues,
      quantity
    }) : undefined;
    setLocalStorageCart(previousData => {
      if (result) return {
        ...result,
        updatedAt: result.updatedAt.toISOString()
      };
      return {
        items: [...previousData.items, {
          id: Date.now(),
          productId,
          optionsValues,
          quantity
        }],
        updatedAt: new Date().toISOString()
      };
    });
  }, [localStorageCart.items, updateCartItem, session.data?.user.idAsNumber, setLocalStorageCart]);
  const clearCart = React.useCallback(async () => {
    await Promise.all(localStorageCart.items.map(({
      id
    }) => removeCartItem(id)));
    if (session.data?.user.idAsNumber) invalidateCart(session.data.user.idAsNumber);
  }, [localStorageCart.items, invalidateCart, removeCartItem, session.data?.user.idAsNumber]);
  const onUserChange: CartContextValue['onUserChange'] = React.useCallback(async params => {
    // The user is signing out, so we need to remove the current products from the Cart without deleting them from the DB
    if (params.action === 'sign-out') {
      deleteCart();
    } else if (params.action === 'sign-in') {
      // The user is signing in
      // The user was already signed in and is signing into a different account, so we need to remove all the products from the localStorage cart
      if (params.previousUserId) {
        deleteCart();
      } else {
        // so we need to transfer everything from the local storage to the DB and fetch the Cart from the DB to fetch products that might have been stored there before
        deleteCart();
        if (localStorageCart.items.length > 0) {
          await Promise.all(localStorageCart.items.map(cartItem => cartItems.addCartItem(params.newUserId, cartItem)));
          await invalidateCart(params.newUserId);
        }
      }
    }
  }, [localStorageCart.items, deleteCart, invalidateCart]);
  const isLoading = React.useMemo((): boolean => {
    if (session.status === 'loading') return true;
    // If the user is not connected we don't need to load server data so no need for loading
    if (!session.data?.user.idAsNumber) return false;
    // If we got server data and that this data is outdated it means the localStorage data is up to date so data is not loading
    if (serverCart && new Date(serverCart.updatedAt) <= new Date(localStorageCart.updatedAt)) return false;
    return true;
  }, [localStorageCart.updatedAt, serverCart, session.data?.user.idAsNumber, session.status]);
  const cartContextValue: CartContextValue = React.useMemo(() => ({
    loading: isLoading,
    cartItems: localStorageCart.items,
    addCartItem,
    updateCartItem,
    removeCartItem,
    clearCart,
    onUserChange
  }), [isLoading, localStorageCart.items, addCartItem, updateCartItem, removeCartItem, clearCart, onUserChange]);
  return <CartContext.Provider value={cartContextValue} data-sentry-element="unknown" data-sentry-component="CartProvider" data-sentry-source-file="useCart.tsx">{children}</CartContext.Provider>;
};
const useCart = () => {
  const cartContext = React.useContext(CartContext);
  if (!cartContext) throw new Error('The useCart hook is being used outside of its provider.');
  return cartContext;
};
export default useCart;